.Invoice {
  width: 794px;
  height: 1123px;
  overflow: hidden;
}

.Header {
  padding-top: 80px;
  padding-bottom: 50px;
}

.Header-logo {
  padding-left: 40px;
  float: left;
}

.Header-logo img {
  width: 250px;
}

.Header-right {
  float: right;
  padding-right: 40px;
}

.Header-title {
  float: right;
  font-size: 18px;
  color: #333;
  line-height: 1.5;
}

.Header-date {
  float: right;
  clear: right;
  font-size: 14px;
  color: #607d8b;
}

.Address {
  padding: 40px 50px;
  margin-top: 50px;
  background-color: #f3f3f3;
}

.Address-Block {
  float: right;
  text-align: right;
}

.Address-Block.is-recipient {
  float: left;
  text-align: left;
}

.Address-Block-name {
  margin: 0;
  line-height: 1.7;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.Address-Block-address {
  color: #607d8b;
  margin: 0;
  font-size: 14px;
}

.Address-Block-address p {
  margin: 0;
  line-height: 1.5;
}

.List {
  width: calc(100% - 80px);
  padding: 0 40px;
  color: #333;
  margin-top: 80px;
}

.List-content {
  width: 100%;
  border-collapse: collapse;
}

.List-content th {
  text-align: left;
  padding: 0 10px 5px 10px;
  font-size: 12px;
  text-transform: uppercase;
  color: #333;
}

.List-content td {
  padding: 10px;
  font-size: 14px;
  line-height: 1.5;
}

.List-content tbody tr {
  border-bottom: 1px solid #b7b7b7;
}

.List-content th.List-number {
  text-align: right;
}

.List-number {
  text-align: right;
}

.List-totals {
  float: right;
  width: 220px;
  border-collapse: collapse;
  margin-top: 15px;
}

.List-totals tr {
  border-bottom: 1px solid #b7b7b7;
}

.List-totals td {
  padding: 10px;
  line-height: 1.5;
  font-size: 14px;
}

.List-totals td:first-child {
  font-size: 14px;
  font-weight: bold;
  text-align: right;
}
