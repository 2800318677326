.payment-success {
  display: flex;
  width: 100%;
  height: 100%;
}

.plan-modal-content {
  background-color: #009688;
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1000px;
  min-width: 300px;
  height: 80vh;
  padding: 2%;
  border-radius: 5px;
}

.plan-modal-content-main {
  background-color: #009688;
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1000px;
  min-width: 300px;
  overflow-y: scroll;
  height: 100%;
}

.plan-modal-content-main {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.plan-modal-content-main::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

/* Layout */
.sr-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 50px 20px;
  z-index: 10;

}

.sr-header {
  color: white;
  font-size: large;
}
/* .togethere-background {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background-color: #009688;
  height: 375px;
} */

.price-table-container {
  display: flex;
  padding: 20px 20px;
  width: 100%;
  justify-content: center;
}

.price-table-container section {
  border-radius: 6px;
  margin: 5px;
  padding: 32px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(60, 66, 87, .08), 0 1px 1px 0 rgba(0, 0, 0, .12);
}

.price-table-container section:first-of-type {
  /* border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 1px solid #efefef; */
}

.price-table-container section:last-of-type {
  /* border-bottom-left-radius: 0px;
  border-top-left-radius: 0px; */
}

.price-table-container button {
  background-color: #F0F0F0;
  border-width: 0px;
  color: #000000;
  border-radius: 64px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 12px;
  padding-left: 12px;
}

.price-table-container button:hover {
  background-color: #d6d6d6;
  filter: none;
}

.price-table-container .name {
  color: #7F7F7F;
  font-size: large;
  margin-top: 8px;
  text-align: center;
}

.price-table-container .price {
  color: #000000;
  font-size: xx-large;
  font-weight: 600;
  margin-top: 8px;
  text-align: center;
}

.price-table-container .duration {
  color: #969696;
  font-size: smaller;
  text-align: center;
  margin-bottom: 24px;
}

.plan-header-text {
  color: #ffffff;
  margin: 20px;
}

.plan-detail-text {
  margin-top: 20px;
}

.plan-detail-text div {
  height: 30px;
  display: flex;
  flex-direction: row;
  color: #ffffff;
}

.plan-detail-text p {
  color: #ffffff;
  margin-left: 5px;
  text-overflow: ellipsis;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
}
