.item {
  position: relative;
  user-select: none;
  padding: 0.5rem;
  margin: 5px;
  align-items: center;
  align-content: center;
  line-height: 1.5;
  border-radius: 3px;
  border: 1px solid lightgrey;
  background: #fff;
}

.item-disabled {
  position: relative;
  user-select: none;
  padding: 0.5rem;
  margin: 5px;
  align-items: center;
  align-content: center;
  line-height: 1.5;
  border-radius: 3px;
  border: 1px solid lightgrey;
  background: #EBEBE4;
}

.clone {
  user-select: none;
  padding: 0.5rem;
  margin: 0 0  0.5rem 0;
  align-items: flex-start;
  align-content: flex-start;
  line-height: 1.5;
  border-radius: 3px;
  border: 3px solid;
  background: #00f;
}
